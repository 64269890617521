@use '../assets/scss/variables' as *; 
@use '../assets/scss/buttons' as *; 

form#form-privacy {
  margin-top: $spacing-medium;

  p {
    &.form-boxes {
      padding-bottom: $spacing-medium;
    }
  }

  label {
    font-size: $font-size-standard;
    font-weight: $font-weight-heavy;
    position: relative;
    top: -$spacing-vsmall;
  }

  input[type="checkbox"] { // target checkboxes
    margin: 0 $spacing-medium $spacing-medium 0;
    width: $spacing-large; // check box size w
    height: $spacing-large; // check box size h
    background-color: $primary-color-highlight;
  }
 
}
// .serviceTable{
//   max-height: calc(100% - 75px);
//  }